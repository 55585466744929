import * as React from 'react'
import { styled, ElevationLevel } from '@thg-commerce/gravity-theme'

const StyledSection = styled.section`
  background-color: ${(props) => props.theme.surface.style.backgroundColor};
  ${(props) => props.theme.elevation.elevationActive && ElevationLevel(1)}
`

export interface SurfaceInterface {
  children?: React.ReactNode
}

/** @component */
export const Surface = <T extends object>(props: T & SurfaceInterface) => {
  const { children, ...other } = props
  return <StyledSection {...other}>{children}</StyledSection>
}

export default Surface
