import * as React from 'react'
import dynamic from 'next/dynamic'

import { Surface, Button, SafeHtml } from '@thg-commerce/gravity-elements'
import {
  styled,
  Text,
  spacing,
  mq,
  ElevationLevel,
} from '@thg-commerce/enterprise-theme'
const SvgCookie = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Cookie'),
)

import { CookieModalProps } from './CookieModal'

const StyledSurface = styled(Surface)<{ withOrder: number }>`
  display: flex;
  box-sizing: border-box;
  order: ${(props) => props.withOrder};
  background-color: ${(props) => props.theme.cookiemodal.backgroundColor};
  padding: ${spacing(3)};
  margin: 0px;
  opacity: 0.95;
  border-top-width: ${(props) => props.theme.cookiemodal.borderWidth};
  border-top-style: ${(props) => props.theme.cookiemodal.borderStyle};
  border-top-color: ${(props) => props.theme.cookiemodal.borderColor};
  width: 100%;

  ${ElevationLevel(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(4)};
  }
`

const StyledSafeHtml = styled(SafeHtml)`
  ${(props) =>
    Text(
      props.theme.cookiemodal.messageText.entry,
      props.theme.cookiemodal.messageText.style,
    )};
  max-width: ${(props) => props.theme.paragraph.maxWidth};
  margin-bottom: ${spacing(3)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-right: ${spacing(4)};
    margin-bottom: 0px;
  }
`

const StyledContent = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`

const StyledWrapper = styled.div<{ withMargin: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.withMargin ? spacing(3) : '0px')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
    margin-left: ${(props) => (props.withMargin ? spacing(4) : '0px')};
  }
`

const StyledCookie = styled(SvgCookie)`
  display: flex;
  overflow: visible;
  fill: ${(props) => props.theme.cookiemodal.svgFillColor};
`

/** @component */
export const CookieModalGravity = (props: CookieModalProps) => {
  return (
    <StyledSurface withOrder={props.order} data-testid="cookie-modal-container">
      <StyledContent>
        {props.showCookieIcon && <StyledCookie />}
        <StyledWrapper withMargin={props.showCookieIcon}>
          <StyledSafeHtml content={props.i18nText.message} />
          <div>
            <Button
              emphasis="medium"
              aria-label={props.i18nText.buttonAriaLabel}
              data-testid={'cookie-accept-button'}
              onClick={props.acceptanceHandler}
            >
              {props.i18nText.button}
            </Button>
          </div>
        </StyledWrapper>
      </StyledContent>
    </StyledSurface>
  )
}

export default CookieModalGravity
