import * as React from 'react'

import { CookieModalElysium } from './CookieModal.ely'
import { CookieModalGravity } from './CookieModal.gravity'
import { RenderFeature, SUPPORTED_FEATURES } from '../Features'

export interface CookieModalI18nText {
  message: string
  button: string
  buttonAriaLabel: string
}

export interface CookieModalProps {
  showCookieIcon: boolean
  acceptanceHandler: (event: React.MouseEvent<Element, MouseEvent>) => void
  order: number
  i18nText: CookieModalI18nText
}

export const CookieModal = (props: CookieModalProps) => {
  return (
    <RenderFeature
      features={[SUPPORTED_FEATURES.ENABLE_GRAVITY_COOKIE_MODAL]}
      renderAlternative={<CookieModalElysium {...props} />}
    >
      <CookieModalGravity {...props} />
    </RenderFeature>
  )
}

export default CookieModal
