import * as React from 'react'
import * as Cookies from 'js-cookie'

import { createPortal } from 'react-dom'

import { getColumboEventData } from '@thg-commerce/enterprise-metrics'

import {
  EnterpriseContext,
  useSiteDefinition,
  useTransmit,
  useSiteConfig,
} from '../../'
import { CookieModal } from '../CookieModal'
import { i18n } from '../i18n'
import { useExperiments } from '../EnterpriseContext'

const COOKIE_NAME = 'cookieNoticeShown'

export interface CookieModalRendererProps {
  atTop: boolean
}

export const CookieModalRenderer = (props: CookieModalRendererProps) => {
  const transmit = useTransmit()

  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const {
    currentLocation: customerLocation,
    cookieModalContainerRef,
  } = EnterpriseCtx

  const { subsite, siteId, channel, defaultLocale } = useSiteDefinition()
  const {
    hideCookieIcon,
    availableLocationsForCookies,
    showOptanonFooterLink,
  } = useSiteConfig()
  const experiments = useExperiments()

  const propertyArgs = {
    subsite,
    siteId,
    channel,
  }

  const cookieSet = typeof Cookies.get(COOKIE_NAME) !== 'undefined'

  const configCanRender =
    !showOptanonFooterLink &&
    experiments['oneTrust_vs_cookieModal'] !== 'v1|hasOneTrust'

  const shouldRender = () => {
    if (
      typeof window === 'undefined' ||
      (typeof defaultLocale === 'undefined' &&
        typeof customerLocation === 'undefined' &&
        customerLocation === '')
    ) {
      return false
    }

    if (cookieSet || !configCanRender) {
      return false
    }

    const cookieLocation =
      typeof customerLocation === 'undefined'
        ? defaultLocale.split(`${subsite}_`)[1]
        : customerLocation.toLowerCase() === 'unknown'
        ? defaultLocale.split(`${subsite}_`)[1]
        : customerLocation.toLowerCase()

    return typeof cookieLocation === 'undefined'
      ? true
      : availableLocationsForCookies
          ?.split(',')
          .map((locale) => locale.toLowerCase())
          .includes(cookieLocation.toLowerCase()) ?? true
  }

  const [canRender, setCanRender] = React.useState(() => shouldRender())

  const container = cookieModalContainerRef?.current

  React.useEffect(() => {
    if (!canRender || !configCanRender) return
    if (EnterpriseCtx.metricNonce === '0') return

    transmit({
      type: 'columbo',
      payload: getColumboEventData({
        propertyArgs,
        argumentsObj: {
          '0': 'Cookie Modal',
          '1': 'Shown',
          '2': 'Cookie Modal Message',
        },
        requestArgs: {
          client_timestamp: Math.round(Date.now() / 1000),
          url: window.location.href,
        },
        eventData: {
          type: 'Shown',
          subtype: 'Cookie Modal',
          contents: [{ html_element: 'Cookie Modal Message' }],
        },
        nonce: EnterpriseCtx.metricNonce,
      }),
    })
  }, [EnterpriseCtx.metricNonce])

  const confirmHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    // TODO: Set expiration depending on site config
    event.stopPropagation()

    Cookies.set(COOKIE_NAME, COOKIE_NAME, {
      expires: 180, // days
      secure: location.protocol.includes('https'),
      path: '/',
    })

    transmit({
      type: 'columbo',
      payload: getColumboEventData({
        propertyArgs,
        argumentsObj: {
          '0': 'Cookie Modal',
          '1': 'Clicked',
          '2': 'Accept Cookie Button',
        },
        requestArgs: {
          client_timestamp: Math.round(Date.now() / 1000),
          url: window.location.href,
        },
        eventData: {
          type: 'Clicked',
          subtype: 'Cookie Modal',
          contents: [{ html_element: 'Accept Cookie Button' }],
        },
        nonce: EnterpriseCtx.metricNonce,
      }),
    })

    setCanRender(false)
  }

  const i18nText = {
    message: i18n('cookie.text.cookiemessage'),
    button: i18n('cookie.button.accept'),
    buttonAriaLabel: i18n('general.cookie.notice.button.arialabel'),
  }

  return (
    <React.Fragment>
      {canRender &&
        createPortal(
          <CookieModal
            showCookieIcon={!hideCookieIcon}
            acceptanceHandler={confirmHandler}
            order={props.atTop ? -1 : 0}
            i18nText={i18nText}
          />,
          container ? container : document.body,
        )}
    </React.Fragment>
  )
}

export default CookieModalRenderer
