import * as React from 'react'

import { Surface, Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { styled, Text, spacing, mq } from '@thg-commerce/enterprise-theme'

import { CookieModalProps } from './CookieModal'

const StyledSurface = styled(Surface)<{ withOrder: number }>`
  display: flex;
  box-sizing: border-box;
  order: ${(props) => props.withOrder};
  background-color: ${(props) => props.theme.cookiemodal.backgroundColor}F2;
  padding: ${spacing(2)};
  margin: 0px;
  opacity: 0.95;
  object-fit: contain;
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(2)};
  }
`

const StyledSafeHtml = styled(SafeHtml)`
  ${(props) =>
    Text(
      props.theme.cookiemodal.messageText.entry,
      props.theme.cookiemodal.messageText.style,
    )};

  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54 !important;
  letter-spacing: 0.19px !important;
  color: #424242;

  margin-bottom: ${spacing(3)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: auto;
    margin-right: ${spacing(4)};
  }

  a {
    font-size: 14px !important;
  }
`

const StyledContent = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
  }
`

/** @component */
export const CookieModalElysium = (props: CookieModalProps) => {
  return (
    <StyledSurface withOrder={props.order} data-testid="cookie-modal-container">
      <StyledContent>
        <StyledWrapper>
          <StyledSafeHtml content={props.i18nText.message} />
          <div>
            <Button
              emphasis="medium"
              aria-label={props.i18nText.buttonAriaLabel}
              data-testid={'cookie-accept-button'}
              onClick={props.acceptanceHandler}
              style={{ width: '100%' }}
            >
              {props.i18nText.button}
            </Button>
          </div>
        </StyledWrapper>
      </StyledContent>
    </StyledSurface>
  )
}

export default CookieModalElysium
